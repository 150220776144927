import React, { FunctionComponent } from 'react';
// @ts-ignore
import HeartSvg from '../images/heart.svg';

const Heart: FunctionComponent = () => {
  return (
    <figure className="c-heart">
      <HeartSvg />
    </figure>
  );
};

export default Heart;
