import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import Heart from '../components/heart';

const IndexPage: FunctionComponent = () => {
  return (
    <>
      <h1>
        Full Stack Developer <Heart /> JavaScript
      </h1>
      <p className="u-accent-first-letter">
        Hello, my name is Korneel Eeckhout. I develop <strong>fast custom web applications</strong> from start to finish, tailor made for
        <strong> your needs</strong>. I can help you <strong>strengthen your web presence</strong> or <strong>automate</strong> tedious
        tasks.
      </p>
      <p>
        I specialize in complex <strong>Javascript</strong> applications using <strong>vanilla JavaScript</strong> or modern frameworks like
        <strong> React</strong> or <strong>Vue</strong>.
      </p>
      <p>
        But I can also build a fitting back-end in <strong>node.js</strong> or <strong>PHP</strong> using an existing CMS like Wordpress, or
        by building a custom <strong>CMS</strong> and/or <strong>REST API</strong>. Whatever the best solution may be.
      </p>
      <p>
        Delivering products that <strong>users love</strong> makes me excited, but not without aspiring to find the most{' '}
        <strong>elegant solution</strong>. I take pride in my craft.
      </p>
      <p>
        Learning is a passion of mine, I try to spread this love trough the <strong>workshops/courses</strong> I teach. Teaching forces me
        to <strong>question my knowledge</strong> and <strong>hone my skills</strong>.
      </p>
      <div className="u-mar-top-2">
        <Link to="/contact/" className="u-button">
          Get in touch..
        </Link>
      </div>
    </>
  );
};

export default IndexPage;
